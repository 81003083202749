<template>
  <div>
    <general-table
      ref="reportsTable"
      :api-url="apiUrl"
      :columns="fields"
      :table-options="tableOptions"
      :search-box="false"
      :selectable="false"
      :export-table="false"
    >
      <template #thead-top>
        <b-tr>

          <b-td
            class="border-0 p-0 pr-1 pb-2"
            colspan="2"
          >
            <b-form-input
              v-model="filter.q"
              type="text"
              placeholder="Search by name"
            />
          </b-td>
          <b-td
            class="border-0 p-0 pr-1 pb-2"
            colspan="2"
          >
            <b-input-group>
              <b-form-select
                v-model="filter.category"
                value-field="value"
                text-field="text"
                :options="categories"
              >
                <template #first>
                  <b-form-select-option
                    disabled
                  >
                    Category
                  </b-form-select-option>
                </template>
              </b-form-select>

              <b-input-group-append v-if="filter.category">
                <b-button
                  size="sm"
                  @click="filter.category = ''"
                >
                  <span class="lead">
                    &times;
                  </span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-td>
          <b-td
            class="border-0 p-0 pr-1 pb-2"
            colspan="2"
          >
            <b-input-group>
              <b-form-select
                v-model="filter.channel"
                value-field="value"
                text-field="text"
                :options="types"
              >
                <template #first>
                  <b-form-select-option
                    disabled
                  >
                    Service Provider
                  </b-form-select-option>
                </template>
              </b-form-select>

              <b-input-group-append v-if="filter.channel">
                <b-button
                  size="sm"
                  @click="filter.channel = ''"
                >
                  <span class="lead">
                    &times;
                  </span>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-td>
          <b-td
            class="border-0 p-0 pb-2"
            colspan="2"
          >
            <b-form-datepicker
              v-model="filter.start_date"
              placeholder="Start Date"
              reset-button
              close-button
              locale="en-GB"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-td>
          <b-td
            class="border-0 p-0 pb-2"
            colspan="2"
          >
            <b-form-datepicker
              v-model="filter.end_date"
              :min="filter.start_date"
              placeholder="End Date"
              reset-button
              close-button
              locale="en-GB"
              class="ml-1"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            />
          </b-td>
          <b-td
            class="border-0 p-0 pb-2 text-right"
            colspan="2"
          >
            <b-button
              variant="primary"
              :disabled="Object.values(filter).length < 1"
              @click="$refs.reportsTable.getAllData()"
            >
              Filter
            </b-button>
            <b-link
              class="ml-1"
              title="Export"
              :href="downloadFile"
            >
              <feather-icon
                icon="DownloadCloudIcon"
                size="23"
                class="text-success"
              />
            </b-link>
          </b-td>
        </b-tr>
      </template>
      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>
      <template #cell(user_name)="data">
        <b-media vertical-align="center">
          <template
            v-if="data.item.image"
            #aside
          >
            <b-avatar
              size="32"
              :src="data.item.image"
            />
          </template>
          <span
            class="font-weight-bold d-block text-nowrap"
          >
            {{ data.item.user_name }}
          </span>
        </b-media>
      </template>

    </general-table>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
// import axios from 'axios'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import AdminDashboardTable from '@/views/dashboard/admin-dashboard/components/AdminDashboardTable.vue'
import timeAgo from '@/global-compositions/timeAgo'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  setup() {
    const report = ref([])

    const filter = ref({})

    const tableOptions = ref({
      filter: null,
      filterIncludedFields: ['phone', 'user_name', 'type_of_transaction', 'service_provider'],
      currentPage: 1,
      perPage: 30,
    })

    const resolveTransactinsStatusVariantAndIcon = status => {
      if (status === true) return { variant: 'light-success', icon: 'CheckCircleIcon' }

      return { variant: 'light-danger', icon: 'InfoIcon' }
    }

    const resolveTransactionsMethod = method => {
      if (method === 'received_money') return { variant: 'light-success', icon: 'ArrowDownRightIcon' }

      return { variant: 'light-info', icon: 'ArrowUpLeftIcon' }
    }

    const fields = [
      { key: 'id', label: '#', sortable: true },
      { key: 'user_name', label: 'User Name' },
      { key: 'phone', label: 'Phone Number' },
      { key: 'amount', label: 'Amount' },
      { key: 'ref_number', label: 'Reference No.' },
      { key: 'category', label: 'Category' },
      { key: 'operator', label: 'Operator' },
      { key: 'service_provider', label: 'Service Provider' },
      { key: 'service_type', label: 'Service Type' },
      { key: 'channel_fees', label: 'Channel Fees' },
      { key: 'zerocash_fees', label: 'Zerocash Fees' },
      { key: 'user_fees', label: 'User Fees' },
      { key: 'profit', label: 'Profit' },
      {
        key: 'created_at',
        label: 'Date',
        // thClass: 'text-center',
        // tdClass: 'text-center',
      },
    ]

    const types = [
      { text: 'Damen', value: 'Damen' },
      { text: 'CashCall', value: 'CashCall' },
      { text: 'Opay', value: 'Opay' },
      { text: 'Waffarha', value: 'Waffarha' },
    ]
    const categories = [
      { text: 'Telecom', value: 'Telecom' },
      { text: 'Charity', value: 'Charity' },
      { text: 'Recharge', value: 'Recharge' },
      { text: 'Wallet', value: 'Wallet' },
    ]

    return {
      resolveTransactinsStatusVariantAndIcon,
      resolveTransactionsMethod,
      timeAgo,
      fields,
      tableOptions,
      types,
      report,
      filter,
      categories,
    }
  },
  computed: {
    apiUrl() {
      let base = 'reports?'

      if (!Object.values(this.filter).length) {
        return base.slice(0, -1)
      }

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(this.filter)) {
        if (key && value) {
          base += `${key}=${value}&`
        }
      }

      return base.slice(0, -1)
    },
    downloadFile() {
      return `https://v2.zerocash.co/${this.apiUrl}`
    },
  },
}
</script>

<style>

</style>
